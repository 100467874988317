"use strict";

jQuery.fn.extend({
    slideDownFadeIn: function(dur, callback) {
        var self = this;

        self.slideDown(dur, function() {
            self.addClass('shown');
        });
        setTimeout(function() {
            if (callback) {
                callback.call(self);
            }
        }, dur);
        return self;
    },
    fadeOutSlideUp: function(dur, callback) {
        var self = this;
        
        self.removeClass('shown');
        setTimeout(function() {
            self.slideUp(dur);
        }, dur);
        setTimeout(function() {
            if (callback) {
                callback.call(self);
            }
        }, dur*2);
        return self;
    }
});

(function($) {
    var $body = $('body'),
    $document = $(document),
    $window = $(window),
    // cssBreakPoints = {
       //  'xs': 480,
       //  'sm': 768,
       //  'md': 992,
       //  'lg': 1250
    // },
    transitionDuration = 200;

    $(function() {

        var $hidePopup = $('.hide-popup');
        $hidePopup.click(function() {
            $body.removeClass('popup-shown');
            setCookie('popupWasShown', true, {
                path: '/',
                expires: (new Date(new Date().getTime() + 30 * 60 * 1000)).toUTCString()//30 min
            });
        });

        var $showPopup = $('.show-popup');
        $showPopup.click(function() {
            $body.addClass('popup-shown');
        });

        var $sectionTopParallaxed = $('#section-top-parallaxed'),
            sectionTopParallaxedHeight = $sectionTopParallaxed.outerHeight(),
            winScroll = $window.scrollTop(),
            $parallaxedText = $('#parallaxed-text'),
            parallaxedTextBottomPos = $parallaxedText.offset().top + $parallaxedText.outerHeight(),
            parallaxedTextBottomFinalPos = sectionTopParallaxedHeight - 60;
        
        proceedSectionTopParallax(winScroll, sectionTopParallaxedHeight, $sectionTopParallaxed);
        proceedParallaxedTextParallax();
        
        $window
            .on('scroll', function() {
                winScroll = $window.scrollTop();
                proceedSectionTopParallax(winScroll, sectionTopParallaxedHeight, $sectionTopParallaxed);
                proceedParallaxedTextParallax();
            })
            .on('resize', function() {
                winScroll = $window.scrollTop();
                sectionTopParallaxedHeight = $sectionTopParallaxed.outerHeight();
                $parallaxedText.css({
                    'transform': 'translateY(0)'
                });
                parallaxedTextBottomPos = $parallaxedText.offset().top + $parallaxedText.outerHeight();
                parallaxedTextBottomFinalPos = sectionTopParallaxedHeight - 60;
                proceedSectionTopParallax(winScroll, sectionTopParallaxedHeight, $sectionTopParallaxed);
                proceedParallaxedTextParallax();
            });

        function proceedSectionTopParallax(scroll, scrollHeight, $parallaxedBlock) {

            if (scroll <= scrollHeight) {

                if ($parallaxedBlock.hasClass('parallax-ended')) {
                    $parallaxedBlock.removeClass('parallax-ended');
                }
                var ratio = scroll / scrollHeight;
                $parallaxedBlock.css({
                    'background-position': '50% ' + ((1 - ratio) * 100) + '%',
                    'opacity': 1 - ratio
                });
            } else {
                $parallaxedBlock.addClass('parallax-ended');
            }
        }

        function proceedParallaxedTextParallax() {

            if (winScroll <= sectionTopParallaxedHeight - 60) {
                var ratio = winScroll / (sectionTopParallaxedHeight - 60);
                $parallaxedText.css({
                    'transform': 'translateY(' + (parallaxedTextBottomFinalPos - parallaxedTextBottomPos) * ratio + 'px)'
                });
            }
        }

        var $popupParallaxScrollArea = $('#popup-parallax-scroll-area');
        $popupParallaxScrollArea.on('scroll.parallax', function() {
            proceedSectionTopParallax($popupParallaxScrollArea.scrollTop(), ($popupParallaxScrollArea.children().first().height() - $('#popup-parallax-scroll-area').height()) / 3, $('#popup-background-parallaxed'));
        });

        var $parallaxedText = $('#parallaxed-text');

        var htmlBody =  $('html, body');
    	$('.inner-link').click(function(e) {
            e.preventDefault ? e.preventDefault() : e.returnValue = false;
            var sectionDirection = $(this).attr('href');
           htmlBody.animate({scrollTop:$(sectionDirection).offset().top}, 'slow');
        });

        $('.show-screenshot').magnificPopup({
            type: 'image'
        });

        $('.img-link').magnificPopup({
            type: 'image',
            mainClass: 'mfp-with-zoom',
            zoom: {
                enabled: true,
                duration: 300,
                easing: 'ease-in-out'
            }
        });

        function setCookie(name, value, options) {
            options = options || {};

            var expires = options.expires;

            if (typeof expires == "number" && expires) {
                var d = new Date();
                d.setTime(d.getTime() + expires * 1000);
                expires = options.expires = d;
            }

            if (expires && expires.toUTCString) {
                options.expires = expires.toUTCString();
            }

            value = encodeURIComponent(value);

            var updatedCookie = name + "=" + value;

            for (var propName in options) {
                updatedCookie += "; " + propName;
                var propValue = options[propName];

                if (propValue !== true) {
                    updatedCookie += "=" + propValue;
                }
            }

            document.cookie = updatedCookie;
        }
    });

    $window.on('load', function() {
        $('.custom-scroll').each(function() {
            var $this = $(this),
                $scrollInner = $this.find('.scroll-inner'),
                $fullcontent = $scrollInner.children().first(),
                $scrollDraggerContainer = $this.find('.dragger-container'),
                $dragger = $this.find('.dragger'),
                scrollInnerHeight,
                fullContentHeight,
                scrollLength,
                draggerHeight,
                maxDraggerTop,
                scrollTop;

            getMetrics();

            $scrollInner.on('scroll', function() {
                scrollTop = $scrollInner.scrollTop();
                $dragger.css('top', maxDraggerTop * scrollTop / (fullContentHeight - scrollInnerHeight) + '%');

                if (scrollTop == scrollLength) {
                    $this.addClass('scrolled-to-end');
                } else {

                    if ($this.hasClass('scrolled-to-end')) {
                        $this.removeClass('scrolled-to-end');
                    }

                    if (scrollTop == 0) {
                    
                        if ($this.hasClass('scroll-began')) {
                            $this.removeClass('scroll-began');
                        }
                    } else {
                        $this.addClass('scroll-began');
                    }
                }
            });

            $dragger.on('mousedown.cs-events touchstart.cs-events', function(e) {
                var startY = (e.pageY) ? e.pageY : e.originalEvent.touches[0].pageY,
                    startScroll = $scrollInner.scrollTop();

                $this.addClass('is-dragged');
                $scrollDraggerContainer.on('mousemove.cs-events touchmove.cs-events', function(e) {
                    var moveY = (e.pageY) ? e.pageY : e.originalEvent.touches[0].pageY;
                    $scrollInner.scrollTop(startScroll + (moveY - startY) * fullContentHeight / scrollInnerHeight);
                });
                $document.on('mouseup.cs-events touchend.cs-events touchcancel.cs-events', function() {
                    $scrollDraggerContainer.off('.cs-events');
                    $document.off('.cs-events');
                    $this.removeClass('is-dragged');
                });
            });

            $window.on('resize', function() {
                getMetrics();
            });

            function getMetrics() {
                scrollInnerHeight = $scrollInner.height();
                fullContentHeight = $fullcontent.height();
                scrollLength = fullContentHeight - scrollInnerHeight;

                if (fullContentHeight > scrollInnerHeight) {
                    $this.addClass('is-draggable');
                    draggerHeight = scrollInnerHeight * scrollInnerHeight / fullContentHeight;
                    maxDraggerTop = (1 - draggerHeight / scrollInnerHeight) * 100;
                    $dragger.height(draggerHeight);
                } else {
                    $this.removeClass('is-draggable');
                }
            }
        });
    });
})(jQuery);